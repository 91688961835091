<template>
  <v-app>
    <navigation 
      v-if="authenticated && showNavigation"
      :navigationItems="navigationItems"
    ></navigation>
      <v-main class="bg">
        <v-container v-if="showNavigation">
        <breadcrumbs 
          :routes="routes"
          class="px-0 py-1"
        ></breadcrumbs>
        <h1 
          class="title">{{pageTitle}}
        </h1>
        </v-container>
        <router-view></router-view>
    </v-main>
    <notifications></notifications>
    <base-loader></base-loader>
  </v-app>
</template>

<script>
import Navigation from './components/Navigation'
import Breadcrumbs from './components/Breadcrumbs'
import Notifications from './components/Notifications'
import BaseLoader from './components/BaseLoader'
import { mapGetters } from 'vuex'
import {navigation_items} from './router/subscriber/routes'
import {routes} from './router/subscriber/routes'
import router from './router/subscriber';

export default {
  router,
  name: 'App',
  components: {
    BaseLoader,
    Navigation,
    Breadcrumbs,
    Notifications,
  },
  computed: {
    ...mapGetters('auth', {
      authenticated: 'authenticated'
    }),
    navigationItems () {
      return navigation_items
    },
    routes () {
      return routes
    },
    pageTitle () {
      return this.$route.meta.title
    },
    showNavigation () {
      return this.$route.name !== 'survey_edit_preview'
              && this.$route.name !== 'survey_template_edit_preview'
    }
  },
  created () {
    //TODO change where fetch
    if (this.$store.state.api === 'admin') {
      this.$store.reset()
    } 
    //this.$store.dispatch('language/all')
  }
};
</script>
<style lang="scss" scoped>
@media (min-width: 1904px) {
  .container {
    max-width: 1600px;
  }
}
</style>
