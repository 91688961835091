import Vue from 'vue';
import axios from 'axios'
import App from './App.vue';
import store from './store/subscriber';
import vuetify from './plugins/vuetify';
import i18n from './i18n'
import _ from 'lodash';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

if(process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://007f48dd59a642178c25fcae37246577@sentry.hurja.eu/42',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}

new Vue({
  store,
  vuetify,
	i18n,
  render: (h) => h(App),
}).$mount('#app');
